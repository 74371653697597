import React from 'react';
import { ButtonGroup, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import TicketDetails from '../TicketDetails';
import Categorize from './actions/Categorize';
import Done from './actions/Done';
import Cancel from './actions/Cancel';

export default function Index({tickets, refreshData}) {
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="table assigned">
                <TableBody>
                    {tickets.map((ticket, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell align="left"> 
                                <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                    <Done ticket={ticket} refreshList={refreshData} />
                                    <Categorize ticket={ticket} refreshList={refreshData} />
                                    <Cancel ticket={ticket} refreshList={refreshData} />
                                </ButtonGroup>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TicketDetails ticket={ticket} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>        
    );
}
