import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {DialogTitle, IconButton} from '@mui/material';

import OfflinePinIcon from '@mui/icons-material/OfflinePin';

import { HUB_HOST, HELPME_API_KEY } from '../../../store/constant';

export default function FormDialog({categoryId, categoryName, fetchData}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} color="success" aria-label="disable">
        <OfflinePinIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event)=> {
            event.preventDefault();
            try {
                await fetch(`${HUB_HOST}/api/v1/categories/${categoryId}/disable`, {
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
                });
                fetchData();
                handleClose();   
            } catch (e) {
                console.log(e)
                return;
            }
          },
        }}
      >
        <DialogTitle>Categorias</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente desativar a categoria {categoryName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCELAR</Button>
          <Button type="submit" color='error'>DESATIVAR</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}