import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {DialogTitle} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import { HUB_HOST, HELPME_API_KEY } from '../../../store/constant';

export default function FormDialog({ticket, refreshList}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  const fetchData = async () => {
      try {
          const response = await fetch(`${HUB_HOST}/api/v1/categories`, {
              method: 'GET',
              headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
          });
          const data = await response.json();
          if (response.status === 200) {
            refreshList();
          }
      } catch (e) {
          console.log(e)
          return;
      }
  };

  return (
    <React.Fragment>
      <Button variant="outlined" 
        color="error" 
        size="small" 
        startIcon={<CancelIcon />}
        onClick={handleClickOpen}>
          Cancelar
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event)=> {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const reason_for_cancellation = formJson.reason_for_cancellation;
            try {
                await fetch(`${HUB_HOST}/api/v1/tickets/${ticket.id}/cancel`, {
                    body: JSON.stringify({ticket: {reason_for_cancellation: reason_for_cancellation}}),
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
                });
                fetchData();
                handleClose();   
            } catch (e) {
                console.log(e)
                return;
            }
          },
        }}
      >
        <DialogTitle>Cancelar suporte</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="reason_for_cancellation"
            name="reason_for_cancellation"
            multiline
            label="Por que resolveu cancelar?"
            fullWidth
            variant="standard" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Voltar</Button>
          <Button type="submit" color='error'>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}