import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider } from '../components/auth/AuthContext';
import RouteGuard from '../components/auth/RouteGuard';

import Container from '@mui/material/Container';

import Posts from '../components/posts/Index.mobile';
import ListNews from '../components/news/Index.mobile.jsx';
import News from '../components/news/News';
import Evaluations from '../components/evaluations/Index';
import Groups from '../components/evaluations/Groups';
import Users from '../components/evaluations/Users';
import Logout from '../components/auth/Logout';
import Search from '../components/Search';
import Departments from '../components/departments/Index';
import PostDepartments from '../components/departments/posts';
import Reports from '../components/reports/Index';
import Contacts from '../components/contacts/Index.mobile';
import Bi from '../components/bi/Index';
import WorkList from '../pages/works/index';
import WorkShow from '../pages/works/show';
import Login from '../components/auth/Login.mobile';
import ForgotPassword from '../components/auth/ForgotPassword';

import MenuBottom from '../components/MenuBottom';

import Menu from '../components/Menu';

import Helpme from '../helpme/Index';
import HelpmeCreate from '../helpme/Create';
import HelpmeAdmin from '../helpme/admin/Index';
import HelpmeAdminCategories from '../helpme/admin/categories/Index';

function MobileRoutes() {

  const PrivateRoutes = ({ children }) => {
    return(
      <RouteGuard>
        <Menu />
        <Container sx={{mb: 10}}>
          {children}
        </Container>
        <MenuBottom />
      </RouteGuard>
    ) 
  }

  return (
    <AuthProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoutes><ListNews /></PrivateRoutes>} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/logout" element={<PrivateRoutes><Logout /></PrivateRoutes>} />
            <Route path="home" element={<PrivateRoutes><ListNews /></PrivateRoutes>} />
            <Route path="search/:term" element={<PrivateRoutes><Search /></PrivateRoutes>} />
            <Route path="posts" element={<PrivateRoutes><Posts /></PrivateRoutes>} />
            <Route path="news" element={<PrivateRoutes><ListNews /></PrivateRoutes>} />
            <Route path="news/:newsId" element={<PrivateRoutes><News /></PrivateRoutes>} />
            <Route path="evaluations" element={<PrivateRoutes><Evaluations /></PrivateRoutes>} />
            <Route path="evaluations/:evaluationId/groups" element={<PrivateRoutes><Groups /></PrivateRoutes>} />
            <Route path="evaluations/:evaluationId/groups/:groupId/users" element={<PrivateRoutes><Users /></PrivateRoutes>} />
            <Route path="departments" element={<PrivateRoutes><Departments /></PrivateRoutes>} />
            <Route path="departments/posts" element={<PrivateRoutes><PostDepartments /></PrivateRoutes>} />
            <Route path="reports" element={<PrivateRoutes><Reports /></PrivateRoutes>} />
            <Route path="contacts" element={<PrivateRoutes><Contacts /></PrivateRoutes>} />
            <Route path="bi" element={<PrivateRoutes><Bi /></PrivateRoutes>} />
            <Route path="works/" element={<PrivateRoutes><WorkList /></PrivateRoutes>} />
            <Route path="works/:id" element={<PrivateRoutes><WorkShow /></PrivateRoutes>} />
            <Route path="works/reports" element={<PrivateRoutes><WorkList /></PrivateRoutes>} />
            <Route path="helpme/admin" element={<PrivateRoutes><HelpmeAdmin /></PrivateRoutes>} />
            <Route path="helpme/admin/categories" element={<PrivateRoutes><HelpmeAdminCategories /></PrivateRoutes>} />
            <Route path="helpme/tickets" element={<PrivateRoutes><Helpme /></PrivateRoutes>} />
            <Route path="helpme/tickets/create" element={<PrivateRoutes><HelpmeCreate /></PrivateRoutes>} />
          </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default MobileRoutes;
