import React, {useEffect, useState} from 'react';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import CreateModal from './Create';
import EditModal from './Edit';
import DisableModal from './Disable';

import Header from '../../Header';

import { HUB_HOST, HELPME_API_KEY } from '../../../store/constant';

export default function Index() {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
        const response = await fetch(`${HUB_HOST}/api/v1/categories`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
        });
        const data = await response.json();
        
        if (data) {setCategories(data);}
    } catch (e) {
        console.log(e)
        return;
    }
};
      

  return (
    <> 
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
        <Link underline="hover" color="inherit" href="/home">
          Página inicial
        </Link>
        <Link underline="hover" color="inherit" href="/helpme/admin">
          Admin
        </Link>
        <Typography sx={{ color: 'text.primary' }}>Categorias</Typography>
      </Breadcrumbs>

      <Header admin={true} />

      <TableContainer component={Paper} elevation={3} sx={{mb: 4, mt: 2}}>
        <Table sx={{ minWidth: 650 }} aria-label="table categories">
          <TableHead>
            <TableRow>
              <TableCell>CATEGORIAS</TableCell>
              <TableCell align="right"><CreateModal fetchData={fetchData} /></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                <DisableModal categoryName={category.name} categoryId={category.id} fetchData={fetchData} /> {category.name}
                </TableCell>
                <TableCell align="right">
                  <EditModal categoryName={category.name} categoryId={category.id} fetchData={fetchData} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
