import React, {useEffect, useState} from 'react';

import { ButtonGroup } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { HUB_HOST, HELPME_API_KEY } from '../../store/constant';

import Categorize from './actions/Categorize';
import TicketDetails from '../TicketDetails';


export default function Index() {
    const [tickets, setTickets] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${HUB_HOST}/api/v1/tickets/completed`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
            });
            const data = await response.json();
            if (response.status === 200) { setTickets(data); }
        } catch (e) {
            console.log(e)
            return;
        }
    };

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="table doned">
                <TableBody>
                    {tickets.map((ticket, index) => (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left">
                            {ticket.category_name ? null :
                            <ButtonGroup orientation="vertical" aria-label="Vertical button group"> 
                                <Categorize ticket={ticket} refreshList={fetchData} />
                            </ButtonGroup>}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TicketDetails ticket={ticket} refreshList={fetchData} />
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
