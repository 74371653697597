import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {DialogTitle, IconButton} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { HUB_HOST, HELPME_API_KEY } from '../../../store/constant';

export default function FormDialog({fetchData}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} color="success" aria-label="create">
        <AddCircleIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event)=> {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const name = formJson.name;
            try {
                await fetch(`${HUB_HOST}/api/v1/categories`, {
                    body: JSON.stringify({category: {name: name}}),
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
                });
                fetchData();
                handleClose();   
            } catch (e) {
                console.log(e)
                return;
            }
          },
        }}
      >
        <DialogTitle>Categorias</DialogTitle>
        <DialogContent>
          <DialogContentText>
            As categorias serão utilizadas no sistema para classificar os tickets.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Nome"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" color='success'>Adicionar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}