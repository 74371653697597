import React, {useEffect, useState} from 'react';
import { Button, IconButton, Typography, Stack, Alert, AlertTitle } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HelpIcon from '@mui/icons-material/Help';
import { useCookies } from 'react-cookie';

import Header from './Header';
import Cancel from './admin/actions/Cancel'

import { HUB_HOST, HELPME_API_KEY } from '../store/constant';


export default function Index() {
    const [cookies] = useCookies([]);
    const [tickets, setTickets] = useState([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const uid = cookies.userinfo.preferred_username
            const response = await fetch(`${HUB_HOST}/api/v1/requesters/${uid}/tickets`, {
                method: 'GET',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
            });
            const data = await response.json();
            if (response.status === 200) {setTickets(data);}
        } catch (e) {
            console.log(e)
            return;
        }
    };

    const isAdmin = () => {
        return cookies.userinfo.groups[0] === 'DTI';
    }

    const statuses = (status) => {
        const collection = new Map();
        collection.set('open', "Aguardando atendimento");
        collection.set('doing', "Em atendimento");
        collection.set('done', "Atendimento finalizado");
        collection.set('canceled', "Atendimento cancelado");
            
        return collection.get(status)
    }

    const canCancel = (status) => {
        return (status !== "done" && status !== "canceled")
    }

  return (
    <>  
       <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
            <Link underline="hover" color="inherit" href="/">
            Página inicial
            </Link>
            <Typography sx={{ color: 'text.primary' }}>Meus pedidos de suporte</Typography>
        </Breadcrumbs>
      
        <Header>
            {isAdmin() ? 
            <Button variant="contained" color='secondary' startIcon={<AdminPanelSettingsIcon />} href="/helpme/admin">
                Admin
            </Button> : null}
        </Header>

        <TableContainer component={Paper} elevation={3} sx={{mb: 4, mt: 2}}>
            <Table sx={{ minWidth: 650 }} aria-label="table categories">
            <TableHead>
                <TableRow>
                    <TableCell>TICKETS</TableCell>
                    <TableCell align="right">
                        <IconButton href="/helpme/tickets/create" color="success" aria-label="create">
                            <AddCircleIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tickets.map((ticket, index) => (
                <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left"></TableCell>
                    <TableCell component="th" scope="row">
                        <Stack
                            direction="column"
                            spacing={0.5}
                            sx={{width: '100%', mb: 1 }}>
                            
                            <Alert icon={<HelpIcon fontSize="inherit" />} severity="info">
                                <AlertTitle>Pedido de suporte - {ticket.created_at_formated}</AlertTitle>
 
                                <Typography variant="overline" component="p">
                                    <b>Solicitante: </b> {ticket.requester_name} - {ticket.requester_department}
                                </Typography>

                                {ticket.description}
                            </Alert>

                            <Typography variant="overline">
                                <b>Status: </b> {statuses(ticket.status)}
                            </Typography>

                            {ticket.attendant_name ? <Typography variant="overline">
                                <b>Atendente: </b> {ticket.attendant_name} - {ticket.attendant_department}
                            </Typography> : null}

                            {ticket.resolution ? <Typography variant="overline">
                                <b style={{color: '#2e7d32'}}>Como foi resolvido: </b> {ticket.resolution}
                            </Typography> : null}

                            {ticket.reason_for_cancellation ? <Typography variant="overline">
                                <b style={{color: '#d32f2f'}}>Motivo do cancelamento: </b> {ticket.reason_for_cancellation}
                            </Typography> : null}

                        </Stack>
                        {canCancel(ticket.status) ? <Cancel ticket={ticket} refreshList={fetchData} /> : null }
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    </>
  );
}
