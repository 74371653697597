import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {DialogTitle, IconButton} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { HUB_HOST, HELPME_API_KEY } from '../../../store/constant';

export default function FormDialog({ticket, refreshList}) {
  const [open, setOpen] = useState(false);
  const [resolution, setResolution] = useState(ticket.resolution);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeResoution = (e) => {
    setResolution(e.target.value)
  }

  return (
    <React.Fragment>
      <IconButton aria-label="edit" 
        size="small"
        onClick={handleClickOpen}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event)=> {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const newResolution = formJson.resolution;
            try {
                await fetch(`${HUB_HOST}/api/v1/tickets/${ticket.id}/edit`, {
                    body: JSON.stringify({ticket: {resolution: newResolution}}),
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
                });
                refreshList();
                handleClose();   
            } catch (e) {
                console.log(e)
                return;
            }
          },
        }}
      >
        <DialogTitle>Atualizar resolução</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="resolution"
            name="resolution"
            onChange={(e) => handleChangeResoution(e)}
            value={resolution}
            multiline
            label="Como você resolveu?"
            fullWidth
            variant="standard" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Voltar</Button>
          <Button type="submit" color='success'>Atualizar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}