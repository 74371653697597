import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Stack, Button, Typography, FormGroup, FormControlLabel, Checkbox  } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import CategoryIcon from '@mui/icons-material/Category';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TaskIcon from '@mui/icons-material/Task';
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';

import Tickets from './Tickets';
import Assigned from './Assigned';
import Doned from './Doned';
import Canceled from './Canceled';

import Header from '../Header';

import { useCookies } from 'react-cookie';

import { HUB_HOST, HELPME_API_KEY } from '../../store/constant';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const OPENED_TICKETS_TAB = 0;
const ASSIGNED_TAB = 1;
const DONE_TAB = 2;
const CANCELLED_TAB = 3;

export default function Index() {
  const [cookies] = useCookies([]);
  const [value, setValue] = useState(ASSIGNED_TAB);
  const [ticketsAssignedAmount, setTicketsAssignedAmount] = useState(0);
  const [ticketsAssigned, setTicketsAssigned] = useState([]);
  const [openedTicketsAmount, setOpenedTicketsAmount] = useState(0);
  const [openedTickets, setOpenedTickets] = useState([]);
  const [filterAssignedToMe, setFilterAssignedToMe] = useState(false);

  const handleChange = (event, newValue) => {
    if (newValue === ASSIGNED_TAB) fetchAssignedTickets();
    setValue(newValue);
  };

  const handleFilterAssignedToMe = (event) => {
    event.target.checked ? fetchTicketsAssignedToMe() : fetchTicketsAssignedToAll();
    setFilterAssignedToMe(event.target.checked);
  }

  useEffect(() => {
    fetchAssignedTickets();
    fetchOpenedTickets();
  }, []);

  const fetchAssignedTickets = async () => {
    filterAssignedToMe ? fetchTicketsAssignedToMe() : fetchTicketsAssignedToAll()
  };

  const fetchTicketsAssignedToAll = async () => {
    try {
      const response = await fetch(`${HUB_HOST}/api/v1/tickets/assigned`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
      });
      const data = await response.json();
      if (response.status === 200) {
        setTicketsAssigned(data);
        setTicketsAssignedAmount(data.length);
      }
    } catch (e) {
        console.log(e)
        return;
    }
  };

  const fetchTicketsAssignedToMe = async () => {
    try {
        const uid = cookies.userinfo.preferred_username
        const response = await fetch(`${HUB_HOST}/api/v1/attendants/${uid}/tickets`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
        });
        const data = await response.json();
        if (response.status === 200) {
            setTicketsAssigned(data);
            setTicketsAssignedAmount(data.length);
        }
    } catch (e) {
        console.log(e)
        return;
    }
  };

  const fetchOpenedTickets = async () => {
    try {
        const uid = cookies.userinfo.preferred_username
        const response = await fetch(`${HUB_HOST}/api/v1/kind/dti/tickets`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
        });
        const data = await response.json();
        if (response.status === 200) {
          setOpenedTickets(data);
          setOpenedTicketsAmount(data.length);
        }
    } catch (e) {
        console.log(e)
        return;
    }
  };

  return (
    <> 
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
        <Link underline="hover" color="inherit" href="/home">
          Página inicial
        </Link>
        <Link underline="hover" color="inherit" href="/helpme/tickets">
          Meus pedidos de suporte
        </Link>
        <Typography sx={{ color: 'text.primary' }}>
          Admin
        </Typography>
      </Breadcrumbs>

        <Header admin={true}>
          <Button variant="contained" startIcon={<CategoryIcon />} color='success' href="/helpme/admin/categories">
            Categorias
          </Button>
        </Header>

        <Card elevation={3} sx={{mb: 4, mt: 2}}>
            <CardContent>
              <Stack
                direction="row"
                spacing={1}
                sx={{justifyContent: "flex-end",alignItems: "center"}}>          
              </Stack>
              <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs 
                        value={value} 
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="admin tabs">
                        <Tab
                          icon={<Badge color="primary" badgeContent={openedTicketsAmount}><DescriptionIcon /></Badge>} 
                          label="Abertos" {...a11yProps(OPENED_TICKETS_TAB)} />
                        <Tab 
                          icon={<Badge color="success" badgeContent={ticketsAssignedAmount}><SupportAgentIcon /></Badge>} 
                          label="Atribuídos" {...a11yProps(ASSIGNED_TAB)} />
                          <Tab
                            icon={<TaskIcon />}  
                            label="Finalizados" {...a11yProps(DONE_TAB)} />
                          <Tab
                            icon={<CancelIcon />}  
                            label="Cancelados" {...a11yProps(CANCELLED_TAB)} />
                      </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={OPENED_TICKETS_TAB}>
                      <Tickets tickets={openedTickets} refreshData={fetchOpenedTickets}/>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={ASSIGNED_TAB}>
                    <>
                      {ticketsAssignedAmount > 1 ?
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={handleFilterAssignedToMe} />} label="Listar apenas os tickets atribuídos para mim" />
                        </FormGroup> : null }
                    
                      <Assigned tickets={ticketsAssigned} refreshData={fetchAssignedTickets}/>
                    </>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={DONE_TAB}>
                    <Doned />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={CANCELLED_TAB}>
                    <Canceled />
                  </CustomTabPanel>
              </Box>
            </CardContent>
        </Card>
    </>
  );
}
