import { useState, useEffect } from 'react';

import Content from './Content';
import { HUB_HOST, BI_API_KEY } from '../../store/constant';

function Last() {
    const [report, setReport] = useState([1, 2, 3, 4]);
    const [snippets, setSnippets] = useState([]);
    const [retry, setRetry] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (retry) fetchData();
    }, [retry]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${HUB_HOST}/api/v1/bi/reports`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${BI_API_KEY}` }
            });
            const data = await response.json();
            
            if (data) {
                setReport(data);
                setSnippets(data.snippets);
            }
        } catch (e) {
            setRetry(true);
            return;
        }
    };

    return <Content report={report} snippets={snippets} />;
}

export default Last;
