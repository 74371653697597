import React, { useState, useEffect } from 'react';

import Content from './Content';
import { HUB_HOST, BI_API_KEY } from '../../store/constant';

function Filtered({selectedId}) {
    const [report, setReport] = useState([1,2,3,4]);
    const [snippets, setSnippets] = useState([]);

    useEffect(() => {
        fetchData()
    }, [selectedId]);
    
    const fetchData = async () => {
        try {
            if (selectedId) {
                const response = await fetch(`${HUB_HOST}/api/v1/bi/reports?id=${selectedId}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${BI_API_KEY}`}
                })
                const data = await response.json()
                if (data) {
                    setReport(data);
                    setSnippets(data.snippets);
                }
            }
        } catch (e) {
            return;
        }
    }

    return (
        <Content report={report} snippets={snippets} />
    )
}

export default Filtered;