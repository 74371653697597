import React from 'react';
import { Typography, Stack, Alert, AlertTitle } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import EditReason from './admin/actions/EditReason'

export default function Index({ticket, refreshList}) {

  return (
    <Stack
        direction="column"
        spacing={1}
        sx={{width: '100%'  }}>

        <Alert icon={<HelpIcon fontSize="inherit" />} severity="info">
          <AlertTitle>Pedido de suporte - {ticket.created_at_formated}</AlertTitle>

          {ticket.category_name ? <Typography variant="overline" component="p">
            <b>Categoria: </b> {ticket.category_name} </Typography> : null}
          
          <Typography variant="overline" component="p">
            <b>Solicitante: </b> {ticket.requester_name} - {ticket.requester_department}
          </Typography>

          <div>{ticket.description}</div>
        </Alert>

        {ticket.attendant_name ? <Typography variant="overline" component="p">
            <b>Atendente: </b> {ticket.attendant_name} - {ticket.attendant_department}
        </Typography> : null}
        
        
        {ticket.resolution ? 
          <Stack direction="row" spacing={0.5} sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }}>
            <EditReason ticket={ticket} refreshList={refreshList} />
          
            <Typography variant="overline" component="p">
                <b style={{color: '#2e7d32'}}>Como foi resolvido: </b> {ticket.resolution}
            </Typography> </Stack> : null}

        {ticket.reason_for_cancellation ? <Typography variant="overline" component="p">
            <b style={{color: '#d32f2f'}}>Motivo do cancelamento: </b> {ticket.reason_for_cancellation}
        </Typography> : null}

    </Stack>
  );
}
