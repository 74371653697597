import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Select, MenuItem, Stack, Card, CardContent, FormControl, InputLabel, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { useCookies } from 'react-cookie';

import { HUB_HOST, HELPME_API_KEY } from '../store/constant';

import Header from './Header';

export default function OpenModal() {
  const [cookies] = useCookies([]);
  const [kind, setKind] = useState('dti');
  const [description, setDescription] = useState("");
  const [requesterUid] = useState(cookies.userinfo.preferred_username);
  const [requesterName] = useState(cookies.userinfo.name);
  const [requesterDepartment] = useState(cookies.userinfo.groups[0] ? cookies.userinfo.groups[0] : "");
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);

  const handleChange = (event) => {
    setKind(event.target.value);
  };

  const handleSubmit = async () => {
    
    const body = JSON.stringify({ 
      ticket: {
        kind: kind, 
        requester_uid: requesterUid, 
        requester_name: requesterName, 
        requester_department: requesterDepartment, 
        description: description
      }
    })

    try {
        await fetch(`${HUB_HOST}/api/v1/tickets`, {
            body: body,
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
        });
        window.location.replace('/helpme')
    } catch (e) {
        console.log(e)
        return;
    }
  }

  const handleDescription = (e) => {
    setDescription(e.target.value)
    e.target.value.length >= 3 ? setDisableSubmitBtn(false) : setDisableSubmitBtn(true);
  }
  
  return (
    <>  
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
        <Link underline="hover" color="inherit" href="/home">
          Página inicial
        </Link>
        <Link underline="hover" color="inherit" href="/helpme/tickets">
          Meus pedidos de suporte
        </Link>
        <Typography sx={{ color: 'text.primary' }}>Solicitar suporte</Typography>
      </Breadcrumbs>

        <Header />
        
        <Card elevation={3} sx={{mb: 4, mt: 1}}>
            <CardContent>
            <Stack
            direction="column"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "flex-start",
            }}>
              <TextField 
                id="filled-basic" variant="filled"
                label="Quem precisa de suporte?"
                defaultValue={`${requesterName} (${requesterDepartment})`}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }} fullWidth/>
              <FormControl fullWidth>
                <InputLabel id="department">
                  Para que setor você deseja solicitar suporte?
                </InputLabel>
                <Select
                  labelId="department"
                  id="department"
                  label="Para que setor você deseja solicitar suporte?"
                  value={kind}
                  onChange={handleChange}
                  required
                  fullWidth>
                  <MenuItem value={"dti"}>DTI</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-multiline-flexible"
                label="Descreva o que esta acontecendo ..."
                value={description}
                onChange={(e) => handleDescription(e)}
                multiline
                minRows={10}
                required
                fullWidth/>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            sx={{justifyContent: "flex-start", alignItems: "center", mt: 2}}> 
              <Button variant="contained" startIcon={<CloseIcon />} color="secondary" href='/helpme/tickets'>
                Voltar
              </Button>
              <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSubmit} disabled={disableSubmitBtn}>
                Salvar
              </Button>
          </ Stack>      
            </CardContent>
        </Card>
    </>
  );
}
