import React from 'react';
import { Stack, Typography, Alert} from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function Header({admin, children}) {
  
  return (
    <Alert icon={false} 
      severity="primary" 
      action={<Stack direction="row" spacing={1} sx={{justifyContent: "flex-end", alignItems: "center"}}>{children}</Stack>}>
        <Stack
            direction="row"
            spacing={1}
            sx={{justifyContent: "flex-start", alignItems: "center" }}> 
            {admin ? <AdminPanelSettingsIcon color="secondary" sx={{ fontSize: 30 }} /> : <SupportAgentIcon sx={{ fontSize: 30 }} /> }
            <Typography variant="h5">
                Helpdesk
            </Typography>
        </ Stack>
    </Alert>
  );
}
