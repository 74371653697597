import React from 'react';

import { ButtonGroup, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import LoginIcon from '@mui/icons-material/Login';

import { useCookies } from 'react-cookie';

import { HUB_HOST, HELPME_API_KEY } from '../../store/constant';

import Categorize from './actions/Categorize';
import TicketDetails from '../TicketDetails';


export default function Index({tickets, refreshData}) {
    const [cookies] = useCookies([]);
    
    const assignToSubmit = async (id) => {
        const body = JSON.stringify({ 
          ticket: {
            attendant_uid: cookies.userinfo.preferred_username,
            attendant_name: cookies.userinfo.name, 
            attendant_department: cookies.userinfo.groups[0]
          }
        })
    
        try {
            await fetch(`${HUB_HOST}/api/v1/tickets/${id}/assign`, {
                body: body,
                method: 'PUT',
                headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${HELPME_API_KEY}`}
            });
            window.location.replace('/helpme/admin')
        } catch (e) {
            console.log(e)
            return;
        }
    }

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="table tickets">
                <TableBody>
                    {tickets.map((ticket, index) => (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="left"> 
                            <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                <Button variant="outlined" 
                                    color="success" 
                                    size="small" 
                                    startIcon={<LoginIcon />}
                                    onClick={() => assignToSubmit(ticket.id)} >
                                    Atribuir para mim
                                </Button>
                                
                                <Categorize ticket={ticket} refreshList={refreshData} />
                            </ButtonGroup>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <TicketDetails ticket={ticket} />
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
